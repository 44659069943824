import React, { useEffect, useState, useCallback } from 'react';

function renderTree(data) {
  let output = '🔺 avalanche';
  output += ` <span class="clipboard" data-copy="avalanche">📋</span>`;
  if (data.description) {
    output += ` <span class="tooltip" data-tooltip="${data.description}">ⓘ</span>`;
  }
  output += '\n';

  if (data.flags) {
    output += renderFlags(data.flags, 0, 'avalanche');
  }

  if (data.subcommands) {
    output += renderSubcommands(data.subcommands, 0, 'avalanche');
  }

  return output;
}

function renderFlags(flags, depth, parentPath) {
  let output = '';
  const sortedFlags = Object.entries(flags).sort(([a], [b]) => a.localeCompare(b));

  const maxFlagLength = Math.max(...sortedFlags.map(([flag, details]) =>
    flag.length + (details.type ? details.type.length + 3 : 0)
  ));

  for (const [flag, details] of sortedFlags) {
    let line = `${'│ '.repeat(depth + 1)}├─ <span class="clipboard" data-copy="${parentPath} ${flag}">📋</span> ${flag}`;
    if (details.type) {
      line += ` (${details.type})`;
    }
    const paddingLength = maxFlagLength - (flag.length + (details.type ? details.type.length + 3 : 0));
    line += ' '.repeat(paddingLength + 2);

    let description = details.description || '';
    if (details.alias) {
      description = `${details.alias}, ${description}`;
    }

    output += `${line}${description}\n`;
  }

  return output;
}

function renderSubcommands(subcommands, depth, parentPath) {
  let output = '';
  const sortedSubcommands = Object.entries(subcommands).sort(([a], [b]) => a.localeCompare(b));

  for (const [command, details] of sortedSubcommands) {
    const fullPath = `${parentPath} ${command}`;
    output += renderCommand(command, details, depth, fullPath);
  }

  return output;
}

function renderCommand(command, details, depth, fullPath) {
  let output = `${'│ '.repeat(depth)}├─ 🔺 ${fullPath}`;
  output += ` <span class="clipboard" data-copy="${fullPath}">📋</span>`;
  if (details.description) {
    output += ` <span class="tooltip" data-tooltip="${details.description}">ⓘ</span>`;
  }
  output += '\n';

  if (details.flags) {
    output += renderFlags(details.flags, depth + 1, fullPath);
  }

  if (details.subcommands) {
    output += renderSubcommands(details.subcommands, depth + 1, fullPath);
  }

  return output;
}

function renderMobileList(data) {
  let output = '<ul class="mobile-list">';

  function addCommandToList(command, path) {
    output += `<li><span class="clipboard" data-copy="${path}">${path}</span></li>`;

    if (command.flags) {
      for (const [flag, details] of Object.entries(command.flags)) {
        output += `<li><span class="clipboard" data-copy="${path} ${flag}">${path} ${flag}</span></li>`;
      }
    }

    if (command.subcommands) {
      for (const [subcommand, subDetails] of Object.entries(command.subcommands)) {
        addCommandToList(subDetails, `${path} ${subcommand}`);
      }
    }
  }

  addCommandToList(data, 'avalanche');

  output += '</ul>';
  return output;
}

function App() {
  const [commandTree, setCommandTree] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    fetch('/avalanche_command_tree.json')
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then(data => setCommandTree(data))
      .catch(error => {
        console.error('Error loading command tree:', error);
        setError(error.message);
      });
  }, []);

  const handleCopy = useCallback((event) => {
    const target = event.target;
    if (target.classList.contains('clipboard')) {
      const textToCopy = target.getAttribute('data-copy');
      const originalText = target.textContent;

      const copyToClipboard = (text) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          return navigator.clipboard.writeText(text);
        } else {
          const textArea = document.createElement('textarea');
          textArea.value = text;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
          return Promise.resolve();
        }
      };

      copyToClipboard(textToCopy)
        .then(() => {
          target.textContent = '✅';
          setTimeout(() => {
            target.textContent = originalText;
          }, 1000);
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
          target.textContent = '❌';
          setTimeout(() => {
            target.textContent = originalText;
          }, 1000);
        });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleCopy);
    return () => {
      document.removeEventListener('click', handleCopy);
    };
  }, [handleCopy]);

  const filteredTree = useCallback((tree) => {
    if (!searchTerm) return tree;

    const searchRegex = new RegExp(searchTerm, 'i');

    const filterObject = (obj, path = '') => {
      const newObj = {};
      for (const [key, value] of Object.entries(obj)) {
        const currentPath = path ? `${path} ${key}` : key;
        if (typeof value === 'object' && value !== null) {
          const filteredValue = filterObject(value, currentPath);
          if (Object.keys(filteredValue).length > 0 || searchRegex.test(currentPath)) {
            newObj[key] = { ...filteredValue };
            if (value.flags) {
              newObj[key].flags = value.flags;
            }
            if (value.description) {
              newObj[key].description = value.description;
            }
          }
        } else if (searchRegex.test(currentPath) || searchRegex.test(value)) {
          newObj[key] = value;
        }
      }
      return newObj;
    };

    return filterObject(tree);
  }, [searchTerm]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      <header className="header">
        <h1>Avalanche CLI Command Tree v1.7.1</h1>
        <p>By Chris Fusillo at <a href="https://metapep.com" target="_blank" rel="noopener noreferrer">metapep labs</a></p>
        <a href="https://github.com/ava-labs/avalanche-cli/" target="_blank" rel="noopener noreferrer">
          Install Avalanche CLI v1.7.1
        </a>
      </header>
      <p className="instructions">
        {isMobile ? "Search and copy CLI commands." : "Search CLI commands and flags. Click 📋 to copy the entire command string."}
      </p>
      <input
        type="text"
        placeholder="Search commands..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      {error && <div className="error">Error: {error}</div>}
      {commandTree ? (
        <div className="command-container">
          {isMobile ? (
            <div className="mobile-tree" dangerouslySetInnerHTML={{ __html: renderMobileList(filteredTree(commandTree)) }} />
          ) : (
            <pre className="command-tree" dangerouslySetInnerHTML={{ __html: renderTree(filteredTree(commandTree)) }} />
          )}
        </div>
      ) : (
        <div className="loading">Loading...</div>
      )}
      <footer className="footer">
        <a href="https://github.com/ava-labs/avalanche-cli/releases/tag/v1.7.1" target="_blank" rel="noopener noreferrer">
          Install Avalanche CLI v1.7.1
        </a>
        <p>Interested in starting an <a href="https://docs.avax.network" target="_blank" rel="noopener noreferrer">Avalanche L1</a>?</p>
      </footer>
      <style>{`
        .App {
          background-color: #000000;
          color: #ffffff;
          min-height: 100vh;
          padding: 20px;
          display: flex;
          flex-direction: column;
        }
        h1 {
          text-align: center;
          margin-bottom: 10px;
          font-size: 24px;
        }
        .instructions {
          text-align: center;
          margin-bottom: 20px;
          font-style: italic;
        }
        .search-input {
          display: block;
          width: 80%;
          max-width: 400px;
          margin: 20px auto;
          padding: 10px;
          font-size: 16px;
          background-color: #000000;
          color: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 5px;
        }
        .command-container {
          flex-grow: 1;
          overflow-x: visible;
        }
        .command-tree {
          font-family: monospace;
          font-size: 14px;
          line-height: 1.5;
          padding: 20px;
          background-color: #000000;
          border-radius: 5px;
          overflow-x: visible;
        }
        .mobile-tree {
          font-family: monospace;
          font-size: 14px;
          line-height: 1.5;
          padding: 20px;
          background-color: #000000;
          border-radius: 5px;
        }
        .mobile-list {
          list-style-type: none;
          padding: 0;
        }
        .mobile-list li {
          margin-bottom: 10px;
        }
        .header, .footer {
          text-align: center;
          padding: 10px 0;
        }
        .header a, .footer a {
          color: #FF1800;
          text-decoration: none;
          transition: color 0.3s ease;
          font-weight: bold;
        }
        .header a:hover, .footer a:hover {
          color: #FF6347;
          text-decoration: underline;
        }
        .footer {
          margin-top: 20px;
          border-top: 1px solid #ffffff;
        }
        .footer p {
          margin: 10px 0;
        }
        .clipboard {
          cursor: pointer;
          margin-left: 5px;
        }
        .clipboard:hover {
          opacity: 0.7;
        }
        .tooltip {
          position: relative;
          display: inline-block;
          cursor: help;
          color: #FFFF00;  // Changed from #FF1800 to #FFFF00 (yellow)
        }
        .tooltip:hover::after {
          content: attr(data-tooltip);
          position: absolute;
          left: 100%;
          top: 60%;
          transform: translateY(-50%);
          background-color: #333;
          color: #fff;
          padding: 8px 12px;
          border-radius: 4px;
          white-space: normal;
          z-index: 100;
          font-size: 12px;
          max-width: 400px;
          min-width:  400px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          border: 1px solid #555;
        }
        @media (max-width: 768px) {
          h1 {
            font-size: 20px;
          }
          .search-input {
            width: 90%;
          }
          .command-tree, .mobile-tree {
            font-size: 12px;
            padding: 10px;
          }
          .tooltip:hover::after {
            left: auto;
            right: 0;
            top: 100%;
            transform: none;
            margin-top: 5px;
          }
        }
      `}</style>
    </div>
  );
}

export default App;
